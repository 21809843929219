<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <!--            <el-form
                            ref="queryForm"
                            class="second-header npa-b"
                            size="small"
                            :inline="true"
                            :model="queryFormModel"
                        >
                            <el-form-item
                                prop="statDateDay"
                                label="统计时间"
                            >
                                <el-date-picker
                                    :picker-options="$utils.CommonConfig.pickerOptions"
                                    v-model="queryFormModel.statDateDay"
                                    type="daterange"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                />
                            </el-form-item>
                            <el-form-item>
                                <el-button
                                    type="primary"
                                    @click="onQuery()"
                                >
                                    查询
                                </el-button>
                                <el-button
                                    type="success"
                                    @click="onReset"
                                >
                                    重置
                                </el-button>
                            </el-form-item>
                        </el-form>-->
            <el-row
                class="second-header bc-t npa-a"
            >
                <el-col :span="6">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="$router.push('/order/order/storeOrder')"
                        >
                            <div
                                class="icon bc-green tc-w"
                                style="width: 200px">
                                <div
                                    class="label"
                                    style="font-size: 38px">
                                    待自取数量
                                </div>
                            </div>
                            <div class="body">
                                <div
                                    style="font-size: 60px"
                                    class="fw-bold"
                                    align="center">
                                    {{ statMap.waitPickupNum || 0 }}
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"

                    >
                        <div
                            class="small-card"
                            @click="$router.push('/order/order/communityOrder?orderStatus=orderDelivery-30')"
                        >
                            <div
                                class="icon bc-green tc-w"
                                style="width: 200px">
                                <div
                                    class="label"
                                    style="font-size: 38px">
                                    待分拣数量
                                </div>
                            </div>
                            <div class="body">
                                <div
                                    style="font-size: 60px"
                                    class="fw-bold"
                                    align="center">
                                    {{ statMap.waitStockNum || 0 }}
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="$router.push('/order/order/communityOrder?orderStatus=orderDelivery-30')"
                        >
                            <div
                                class="icon bc-green tc-w"
                                style="width: 200px">
                                <div
                                    class="label"
                                    style="font-size: 38px">
                                    待配送数量
                                </div>
                            </div>
                            <div class="body">
                                <div
                                    style="font-size: 60px"
                                    class="fw-bold"
                                    align="center">
                                    {{ statMap.waitShipNum || 0 }}
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="$router.push('/order/order/communityOrder?orderStatus=30-2')"
                        >
                            <div
                                class="icon bc-green tc-w"
                                style="width: 200px">
                                <div
                                    class="label"
                                    style="font-size: 38px">
                                    配送中数量
                                </div>
                            </div>
                            <div class="body">
                                <div
                                    style="font-size: 60px"
                                    class="fw-bold"
                                    align="center">
                                    {{ statMap.shippingNum || 0 }}
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col
                    :span="6"
                    v-if="userData.appId !== 3">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="$router.push('/order/orderImp/orderImp?orderStatus=0&isOrderException=1')"
                        >
                            <div
                                class="icon bc-second tc-w"
                                style="width: 200px">
                                <div
                                    class="label"
                                    style="font-size: 38px">
                                    异常订单数
                                </div>
                            </div>
                            <div class="body">
                                <div
                                    style="font-size: 60px"
                                    class="fw-bold"
                                    align="center">
                                    {{ statMap.orderExceptionNum || 0 }}
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <template
            >
                <el-row>
                    <el-col
                        :span="10"
                        v-show="userData.appId === 2">
                        <!-- 表格 -->
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="tableData"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="storeName"
                                label="门店名称"
                                min-width="160"
                            />
                            <el-table-column
                                prop="storeSn"
                                label="门店编码"
                                min-width="200"
                            />
                            <el-table-column
                                prop="waitStockNum"
                                label="待分拣数量"
                                min-width="100"
                            />
                            <el-table-column
                                prop="waitShipNum"
                                label="待配送数量"
                                min-width="100"
                            />
                            <el-table-column
                                prop="shippingNum"
                                label="配送中数量"
                                min-width="100"
                            />
                        </el-table>

                        <!-- 分页 -->
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="pagination"
                                @input="onQuery"
                            />
                        </div>
                    </el-col>
                    <el-col :span="userData.appId === 2 ? 14 : 24">
                        <!-- 表格 -->
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="tableData1"
                            style="width: 100%"
                            class="ma-l"
                        >
                            <el-table-column
                                prop="storeName"
                                label="门店名称"
                                min-width="160"
                            />
                            <el-table-column
                                prop="orderSn"
                                label="订单号"
                                min-width="200"
                            />
                            <el-table-column
                                label="付款时间"
                                width="135"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="orderStatusName"
                                label="订单状态"
                                min-width="100"
                            />
                            <el-table-column
                                prop="pickerDriverName"
                                label="分拣员"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.pickerDriverName }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="driverName"
                                label="配送员"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.driverName }}
                                </template>
                            </el-table-column>
                        </el-table>

                        <!-- 分页 -->
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="pagination1"
                                @input="getListData1"
                            />
                        </div>
                    </el-col>
                </el-row>
            </template>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'DistributionStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
            },
            // 表格数据
            tableData: [],
            statMap: {},
            tableData1: [],
            pagination1: {
                pageSize: 20,
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.ShopStoreStat.distributionStatList({ ...queryData }).then(json => {
                const res = json.data.data.storeList;
                this.tableData = res.data;
                this.pagination = res.pagination;

                this.statMap = json.data.data.statMap || {};
                return Promise.resolve({ data: res });
            });
        },
        getListData1(queryData) {
            return this.$api.Ex.Order.orderList({
                ...queryData,
                orderStatusList: '20,30,40',
                isStoreOrder: 1,
                orderByKey: 'paymentTime',
                orderByType: 'asc',
            }).then(json => {
                const res = json.data;
                this.tableData1 = res.data;
                this.pagination1 = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
    created() {
        this.getListData1({ ...this.pagination1 });
        const timer = setInterval(() => {
            this.onQuery(this.pagination);
            this.getListData1({ ...this.pagination1 });
        }, 10000);
        this.$once('hook:beforeDestroy', () => {
            clearInterval(timer);
        });
    },
};
</script>

<style lang="scss">
</style>
